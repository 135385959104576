import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Protected from "../../Protected/index";
import PublicRoute from "../../Protected/PublicRoute";
import Logout from "../../modules/Logout/index";
import NotFound from "../../modules/NotFound/index";
import LandingPage from "../../modules/LandingPage/index";
import Sidebar from "../DashboardLayoutV2/Sidebar/Sidebar.component";
import CustomerReview from "../../modules/Home/CustomerReview/CustomerReview.component";
import { routes } from "../../common/routeUrls";
import AccountInfo from "../../modules/Settings/AccountInfo.component";
import QrCodeWithUser from "../../modules/QrCodes/QrcodesListing.componet";

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <LandingPage />
            </PublicRoute>
          }
        />
        <Route
          path="/logout"
          element={
            <Protected>
              <Logout />
            </Protected>
          }
        />
        <Route
          path={routes.home}
          element={
            <Protected>
              <CustomerReview />
            </Protected>
          }
        />
        <Route
          path={routes.qrCodes}
          element={
            <Protected>
              <QrCodeWithUser />
            </Protected>
          }
        />
        <Route
          path={routes.settings}
          element={
            <Protected>
              <AccountInfo />
            </Protected>
          }
        />
        <Route path="/test" element={<Sidebar />} />
        <Route
          path="*"
          element={
            <Protected>
              <NotFound />
            </Protected>
          }
        />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
