import React, { useState } from "react";
import "./index.css";
import Login from "./Auth/Login/Login.component";
import Signup from "./Auth/Signup/Singup.component";
import ButtonMain from "../../components/Button/ButtonMain";
import Logo from "../../common/Icons/Logo";
import CheckedIcon from "../../common/Icons/CheckedIcon";
import moment from "moment/moment";
import { IN_SHORT_NAME } from "../../common/Constants";
import { getUserCountry } from "./Auth/Auth.actions";
import { useSearchParams } from "react-router-dom";
import ForgotPassword from "./Auth/ForgotPassword/ForgotPassword.component";
// import BasicLogin from "./Auth/BasicLogin/BasicLogin.component";

function LandingPage() {
  const [params] = useSearchParams();
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [country, setCountry] = useState();
  const [isForgotPassModalOpen, setForgotPassModalOpen] = useState(false);

  const setUserCountry = async () => {
    let res = await getUserCountry();
    res = res !== null && res !== undefined ? res : IN_SHORT_NAME;
    setCountry(res);
    if(params.get("login")){
      setIsLoginOpen(true)
    }
  };

  useState(() => {
    document.title = "QR Pro Studio";
    setUserCountry();
  }, []);

  const toggleModal = (type) => {
    if (type === "login") {
      setIsLoginOpen((prev) => !prev);
      setIsSignupOpen(false);
      setForgotPassModalOpen(false);
    } else if (type === "signup") {
      setIsSignupOpen((prev) => !prev);
      setIsLoginOpen(false);
    } else {
      setForgotPassModalOpen((prev) => !prev);
      setIsSignupOpen(false);
      setIsLoginOpen(false);
    }
  };

  return (
    <>
      {isLoginOpen && (
        <Login
          isOpen={isLoginOpen}
          onClose={() => toggleModal("login")}
          country={country}
          toggleModal={toggleModal}
        />
      )}
      {isSignupOpen && (
        <Signup
          isOpen={isSignupOpen}
          onClose={() => toggleModal("signup")}
          country={country}
          toggleModal={toggleModal}
        />
      )}
      {isForgotPassModalOpen && (
        <ForgotPassword
          isOpen={isForgotPassModalOpen}
          onClose={() => toggleModal("forgotpassword")}
          toggleModal={toggleModal}
        />
      )}

      <div className="app-landing-page">
        <header className="app-header d-flex align-items-center justify-content-between ">
          <div className="d-flex align-items-center">
            <img src="/app_logo.png" alt="logo" className="app-header__logo" />
            <p className="app-header__app-name">QR Pro Studio</p>
          </div>
          <div>
            <button
              className="app-header__login-btn"
              onClick={() => toggleModal("login")}
            >
              Login
            </button>
            <button
              className="app-header__login-btn app-signup-btn"
              onClick={() => toggleModal("signup")}
            >
              Signup
            </button>
          </div>
        </header>
        <section className="app-introduction container">
          <div className="app-intro-conatiner">
            <p className="app-intro-title">
              QR Codes For Customer Feedback
            </p>
            <div className="text-center d-flex justify-content-center icons-root">
              <p className="app-intro-sub-title ">
                Gather honest private feedback. Eliminate public negative reviews. Direct Positive Reviews to Google.
              </p>
              <img
                src="/landing-page/3dicons-2.png"
                alt="chat-icon"
                className="app-intro-conatiner__chat_icon chat-icon-left"
              />
              <img
                src="/landing-page/3dicons-3.png"
                alt="chat-icon"
                className="app-intro-conatiner__chat_icon chat-right-icon"
              />
            </div>
          </div>
          <div className="d-flex flex-column align-items-center">
            <ButtonMain
              text={"Get Started"}
              additionalClass="get-started-button m-3"
              onClick={() => toggleModal("signup")}
            ></ButtonMain>
            <img
              src="/landing-page/feedback-screen-2-1.svg"
              alt="application dashboard image"
              className="app-dashboard-imgs"
            />
          </div>
        </section>
        <section>
          <p className="mt-5 margin-auto app-steps">
            All-in-One QR Code Management Dashboard
          </p>
          <p className="app-steps-sub mt-2 text-center">
            Create, manage, and track QR codes and feedback effortlessly to
            enhance your service and grow your business
          </p>
          <div className="container mt-2">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div className="app-steps-cards">
                <img src="/landing-page/QR Code.jpg" />
                <p className="card-title">Create QR Code</p>
                <p className="card-desription">
                  Generate unique QR codes in seconds to start collecting
                  valuable customer feedback.
                </p>
              </div>
              <div className="app-steps-cards">
                <img src="/landing-page/Excellent Review.jpg" />
                <p className="card-title">Gather Real-Time Feedback</p>
                <p className="card-desription">
                  Collect valuable insights instantly, enabling you to make
                  timely improvements to your service
                </p>
              </div>
              <div className="app-steps-cards">
                <img src="/landing-page/Businessman showing high financial risk.jpg" />
                <p className="card-title">Make data-driven decisions </p>
                <p className="card-desription">
                  Leverage detailed customer feedback to make informed decisions
                  and drive sustainable growth.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="container get-started-section">
          <img src="/landing-page/thumbs-up.png" className="thumbs-up-icon" />
          <img src="/landing-page/card.png" className="card-icon " style={{ visibility: 'hidden' }} />
          <div className="get-started-for-free w-100 text-center">
            <p className="card-heading">Get Started for Free</p>
            <p className="card-heading-subheading mt-4">
              Explore our features with a free trial and see the difference.
            </p>
            <div className="d-flex flex-column align-items-center justify-content-center mt-4">
              <div className="d-flex align-items-center">
                <CheckedIcon />
                <p className="ms-2">No credit card required</p>
              </div>
              <ButtonMain
                text={"Get started"}
                additionalClass="get-started-button get-started-button-sm"
                onClick={() => toggleModal("signup")}
              />
            </div>
          </div>
        </section>
        <footer
          className="container"
          style={{ marginTop: "3rem", marginBottom: "2rem" }}
        >
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center sm-icon">
                <Logo />
                <p className="ms-3 app-header__app-name">QR Pro Studio</p>
              </div>
              <p className="mt-3 app-outro">
                Built to collect real-time feedback, enhance your services, and
                drive business growth.
              </p>
            </div>
            <div className="col-md-4 text-center col-sm-12 col-sx-12">
              <p style={{ fontWeight: 700 }}>Legal</p>
              <a href="/privacy-policy.html" className="mt-4">
                Privacy Policy
              </a>
            </div>
            <div className="col-md-3 text-center">
              <p style={{ fontFamily: "Inter !important" }}>
                © {moment().format("YYYY")} QrProStudio. All Rights Reserved
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default LandingPage;
