export const BIZ_TYPES_OPTIONS = [
  { value: "select", displayValue: "Select" },
  { value: "restaurant", displayValue: "Restaurant" },
  { value: "clinic", displayValue: "Clinic" },
  { value: "others", displayValue: "Others" },
];

export const MESSAGE_TYPES = {
  info: "info",
  warning: "warning",
  success: "success",
  fail: "fail",
};

export const IN_SHORT_NAME = "IN";


export const CDN_URL = "https://dlhk61ly0tkm5.cloudfront.net";
