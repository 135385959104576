import { apiUrls } from "../../common/apiUrls";
import { putUrl } from "../../common/feedbackApi";
import { notifyWithType } from "../../main/slices/Commons/notificationSlice";
import { store } from "../../main/store";
import { identifiers } from "../../utils/identifiers";

export const dispatchAction = (action) => {
  store.dispatch(action);
};

export const updateUserDetails = async (payload, org_id) => {
  const userId = localStorage.getItem(identifiers.userId);
  const sendData = {
    user: {
      email: payload.email,
      mobile: payload.mobile,
    },
    organization: {
      org_name: payload.name,
      public_review_links: {
        Google: payload.bizUrl || "",
      },
    },
  };
  const result = await putUrl(apiUrls.updateProfile(org_id), sendData);
  if (!result.success) {
    if (
      (result.status === 400 &&
        result.data?.email &&
        result.data?.email &&
        result.data.email[0]) ||
      (result.data?.mobile && result.data?.mobile && result.data.mobile[0])
    ) {
      dispatchAction(
        notifyWithType({
          type: "failure",
          title: "User with Email or Mobile already exists!",
        })
      );
    } else {
      dispatchAction(
        notifyWithType({
          type: "failure",
          title: "Something went wrong!",
        })
      );
    }
  } else {
    dispatchAction(
      notifyWithType({
        type: "success",
        title: "Profile updated successfully!",
      })
    );
  }
};
