import React, { useEffect, useState } from "react";
import "./index.css";
import AppIcon from "../../AppIcons/AppIcon";
import { APP_ICONS } from "../../AppIcons/IconConstants";
import Logo from "../../AppIcons/AppLogo";
import { FaCircleArrowLeft, FaCircleArrowRight } from "react-icons/fa6";
import AppHeader from "./AppHeader.component";
import PropTypes from "prop-types";
import { useNavigate, Link } from "react-router-dom";
import { routes } from "../../../common/routeUrls";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { IoCloseOutline } from "react-icons/io5";
import { FaAnglesLeft } from "react-icons/fa6";

// Better create separate component for AppLayout and Sidebar to loose couple
function AppLayout({ children }) {
  const [active, setActive] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [path, setPath] = useState(routes.testHome || routes.home);
  const navigate = useNavigate();
  const history = useLocation();

  const VERSION_KEY = process.env.REACT_APP_CURRENT_VERSION;

  const handleOnToggle = () => {
    setActive((prev) => !prev);
  };

  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setActive(false);
    } else if (window.innerHeight > 1300) {
      setActive(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setPath(history.pathname);
    // handleToggle()
  }, [history.pathname]);

  const handleToggle = () => {
    setToggle((prev) => !prev);
  };

  return (
    <div className="root-nav">
      <div
        className={classNames(`sidebar ${active && "active"}`, {
          "show-sidebar active": toggle,
        })}
      >
        <div className="top">
          <div className="d-flex align-items-center justify-content-between">
            <Logo />
            <FaAnglesLeft
              size={22}
              color={"rgba(61, 189, 255, 1)"}
              onClick={handleToggle}
              className={classNames({
                "d-none": !toggle,
              })}
            />
          </div>
          {active ? (
            <FaCircleArrowLeft
              className="toggle-btn"
              fontSize={25}
              color="rgba(61, 189, 255, 1)"
              onClick={handleOnToggle}
            />
          ) : (
            <FaCircleArrowRight
              className="toggle-btn"
              fontSize={25}
              color="rgba(61, 189, 255, 1)"
              onClick={handleOnToggle}
            />
          )}
        </div>
        <div className="sidebar__menu-container">
          <ul className={`sidebar__menu-list ${active && "active"}`}>
            <li
              className={`sidebar__menu-items ${path === routes.home ? "selected" : ""} `}
              onClick={() => navigate(routes.home)}
            >
              <AppIcon
                path={APP_ICONS.feedback}
                width="20"
                height="20"
                className={"sidebar-icons"}
                fill={"#202224"}
              ></AppIcon>
              <Link to={routes.home}>Feedback</Link>
            </li>
            <li
              className={`sidebar__menu-items ${path === routes.qrCodes ? "selected" : ""}`}
              onClick={() => navigate(routes.qrCodes)}
            >
              <AppIcon
                className={"sidebar-icons"}
                path={APP_ICONS.qrCode}
                width="20"
                height="20"
                fill={"rgba(32, 34, 36, 0.50)"}
              ></AppIcon>
              <Link to={routes.qrCodes}>QR Codes</Link>
            </li>
            <li
              className={`sidebar__menu-items ${path === routes.settings ? "selected" : ""}`}
              onClick={() => navigate(routes.settings)}
            >
              <AppIcon
                className={"sidebar-icons"}
                path={APP_ICONS.user}
                width="20"
                height="20"
                fill={"rgba(32, 34, 36, 0.50)"}
              ></AppIcon>
              <Link to={routes.settings}>My Account</Link>
            </li>
          </ul>
        </div>
        <div className="sidebar__menu-container" id="sidebar-bottom-menu">
          <ul className={`sidebar__menu-list ${active && "active"}`}>
            {VERSION_KEY !== "v1" && (
              <li className="sidebar__menu-items">
                <AppIcon
                  className={"sidebar-icons settings-icon"}
                  path={APP_ICONS.settings}
                  width="30"
                  height="30"
                  viewBox="0 0 45 45"
                  fill={"rgba(32, 34, 36, 0.50)"}
                ></AppIcon>
                <Link to="/home">Settings</Link>
              </li>
            )}
            <li
              className="sidebar__menu-items"
              onClick={() => navigate(routes.logout)}
            >
              <AppIcon
                className={"sidebar-icons"}
                path={APP_ICONS.logout}
                width="20"
                height="20"
                fill={"rgba(32, 34, 36, 0.50)"}
              ></AppIcon>
              <Link to={routes.logout}>Logout</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={`main-content ${!active ? "exapand" : "shrink"}`}>
        <AppHeader shrink={active} handleToggle={handleToggle} />
        <div className="main-content_content">{children}</div>
      </div>
    </div>
  );
}

AppLayout.propTypes = {
  children: PropTypes.element,
};

export default AppLayout;
