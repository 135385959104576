import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { routes } from "../common/routeUrls";
import { convertToCamelCase } from "../utils/utilities";
import { Skeleton } from "antd";
import { identifiers } from "../utils/identifiers";
import { useEffect } from "react";
import { notifyWithType } from "../main/slices/Commons/notificationSlice";
import { fetchUser } from "../main/slices/User/userSlice";
import Loader from "../common/Icons/Loader";

const withUser = (WrappedComponent) => {
  return (props) => {
    const user = useSelector((state) => state.user);
    const location = useLocation();
    const pathname = location.pathname;

    let bizDetails = convertToCamelCase(user?.user?.organization_details[0]);
    let loading = user?.loading;
    let qrLink = bizDetails?.reviewTemplateDetails?.[0].reviewQrcodeImage;

    const dispatch = useDispatch();
    const isFirstTimeUser = localStorage.getItem(identifiers.is_new_user);

    useEffect(() => {
      let businessDetails = convertToCamelCase(
        user?.user?.organization_details[0]
      );
      let qr =
        (businessDetails &&
          businessDetails?.reviewTemplateDetails?.[0].reviewQrcodeImage) ||
        null;
      let timer = undefined;


      if (isFirstTimeUser && !qr && pathname === routes.qrCodes) {
        timer = setInterval(() => {
          dispatch(fetchUser(localStorage.getItem(identifiers.userId)));
        }, 20 * 1000);
      } else if (isFirstTimeUser) {
        localStorage.removeItem(identifiers.is_new_user);
        dispatch(
          notifyWithType({
            type: "success",
            title: "Succesfully created your QR Code 🚀",
          })
        );
        clearInterval(timer);
      }
      return () => {
        clearInterval(timer);
      };
    }, [user, pathname]);

    if (user?.error) {
      return (
        <div className="container d-flex flex-column align-items-center loading-text">
          <p>OOPS! Something went wrong!</p>
          <p className="loading-text-by mt-3">Try again later.</p>
        </div>
      );
    }

    if (
      bizDetails &&
      (qrLink === null || qrLink === undefined || qrLink === "") &&
      !loading &&
      isFirstTimeUser
    ) {
      return (
        <div className="container d-flex flex-column align-items-center loading-text">
          <p>
            Creating your shortcut to feedback. Hang on, your review QR code is
            almost ready to collect those stars !
          </p>
          <p className="loading-text-by mt-3">- QR Pro Studio</p>
          <Loader />
        </div>
      );
    }

    if (!bizDetails || loading) {
      return (
        <>
          <Skeleton loading={loading} style={{ maxWidth: 1000 }}></Skeleton>
          <Skeleton loading={loading} style={{ maxWidth: 1000 }}></Skeleton>
        </>
      );
    }


    return (
      <WrappedComponent
        user={pathname === routes.qrCodes ? user.user : user}
        {...props}
      />
    );
  };
};

export default withUser;
